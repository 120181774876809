import styled from '@emotion/styled';

type ButtonProps = {
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
};

const BaseButton = styled.button<ButtonProps>`
  pointer-events: ${(props: any) => (props.disabled ? 'none' : 'auto')};
  border: ${(props: any) =>
    `1px solid ${
      !props.disabled
        ? props.variant !== 'secondary'
          ? props.theme.colors.red
          : props.theme.colors['gray-3']
        : props.theme.colors['gray-3']
    }`};
  background-color: ${(props: any) =>
    !props.disabled
      ? props.variant !== 'secondary'
        ? props.theme.colors.red
        : props.theme.colors.white
      : props.theme.colors['gray-6']};
  color: ${(props: any) =>
    !props.disabled
      ? props.variant !== 'secondary'
        ? props.theme.colors.white
        : props.theme.colors['gray-1']
      : props.theme.colors['gray-3']};
  &:hover {
    background-color: ${(props: any) =>
      props.variant !== 'secondary' ? props.theme.colors['primary-red-hover'] : props.theme.colors['gray-5']};
    border-color: ${(props: any) =>
      props.variant !== 'secondary' ? props.theme.colors['primary-red-hover'] : props.theme.colors['gray-3']};
  }
  cursor: pointer;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
`;

export const SmallButton = styled(BaseButton)<ButtonProps>`
  height: 32px;
  padding: 4px 16px 4px 16px;
  border-radius: 20px;
`;

export const Button = styled(BaseButton)<ButtonProps & { expanded?: boolean }>`
  height: 3rem;
  padding: 14px 24px 14px 24px;
  border-radius: 1.5rem;
  @media (max-width: 768px) {
    width: ${(props: any) => (props.expanded ? '100%' : '')};
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const PrivacyPolicyButton = styled(BaseButton)<ButtonProps>`
  height: 3rem;
  padding: 14px 24px 14px 24px;
  border-radius: 1.5rem;
  background: transparent;
  border: none;
  transition: all 250ms;
  span {
    color: transparent !important;
    transition: all 250ms;
  }
  &:hover span {
    color: black !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const IconButton = styled(BaseButton)<ButtonProps>`
  height: 3rem;
  padding: 16px;
  border-radius: 24px;
`;

export const SmallIconButton = styled(BaseButton)<ButtonProps>`
  height: 2.25rem;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;
`;
