import { IconProps } from './types';

const UploadIcon = ({ fill = '#262626', width = 12, height = 10 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.999999 12L0.999999 11L0.999999 10L2 10L2 11L10 11L10 10L11 10L11 11L11 12L0.999999 12ZM6.00007 1L1.79297 5.20711L2.50007 5.91422L5.50007 2.91421L5.50015 9.5L6.50015 9.5L6.50007 2.91421L9.50007 5.91422L10.2072 5.20711L6.00007 1Z'
        fill={fill}
      />
    </svg>
  );
};

export default UploadIcon;
