import { useState } from 'react';

export const useCookieSettings = () => {
  const [areCookieSettingsAccepted, setAreCookieSettingsAccepted] = useState<boolean>(
    localStorage.getItem('cookie-settings') === 'true',
  );

  const acceptCookieSettings = () => {
    localStorage.setItem('cookie-settings', 'true');
    setAreCookieSettingsAccepted(true);
  };

  return {
    areCookieSettingsOpen: !areCookieSettingsAccepted,
    acceptCookieSettings,
  };
};
