import { useEffect, useRef, useState } from 'react';

import { Outlet } from 'react-router-dom';

import {
  AnimationVideo,
  PrivacyPolicyButtonWrapper,
  Logo,
  LogoContainer,
  PrivacyPolicyLink,
  Wrapper,
  ContentWrapper,
} from './MainLayout.styles';
import { DEFAULT_VIDEO, senderVideoConfiguration } from './utils';
import { PrivacyPolicyButton } from '../../components/Button/Button.styles';
import CookieSettings from '../../components/CookieSettings/CookieSettings';
import MoreIcon from '../../components/Icons/MoreIcon';
import { ButtonLabel } from '../../components/Typography/Typography.styles';
import { useCookieSettings } from '../../hooks/useCookieSettings';
import { useQueryParams } from '../../hooks/useQueryParams';
import { GreetingType, WizardSteps } from '../../pages/Sender/CreationWizard/types';

const MainLayout = () => {
  const { areCookieSettingsOpen, acceptCookieSettings } = useCookieSettings();
  const { stepName, wishType } = useQueryParams();

  const [animationVideoUrl, setAnimationVideoUrl] = useState<string>('');

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (
      stepName === WizardSteps.WIZARD_GREETING_CHOICE.toLowerCase() &&
      animationVideoUrl !== senderVideoConfiguration[wishType?.toUpperCase().replaceAll('_', ' ') as GreetingType]
    ) {
      if (videoRef.current) {
        videoRef.current.style.opacity = '0';
        setTimeout(() => {
          const parsedWishType = wishType?.toUpperCase().replaceAll('_', ' ') as GreetingType;
          setAnimationVideoUrl(senderVideoConfiguration[parsedWishType]);
          setTimeout(() => {
            if (videoRef.current) {
              videoRef.current.load();
              videoRef.current.style.opacity = '1';
            }
          }, 300);
        }, 350);
      }

      return;
    }

    if (stepName !== WizardSteps.WIZARD_GREETING_CHOICE.toLowerCase()) {
      if (videoRef.current) {
        videoRef.current.style.opacity = '0';
        setTimeout(() => {
          setAnimationVideoUrl('');
        }, 300);
      }
    }
  }, [stepName, wishType]);

  return (
    <>
      <Wrapper>
        <AnimationVideo autoPlay muted loop>
          <source src={DEFAULT_VIDEO} type='video/mp4' />
        </AnimationVideo>

        {stepName === WizardSteps.WIZARD_GREETING_CHOICE.toLowerCase() && (
          <AnimationVideo ref={videoRef} autoPlay muted loop>
            <source src={animationVideoUrl} type='video/mp4' />
          </AnimationVideo>
        )}
        <LogoContainer>
          <Logo src='/images/abb-logo.svg' />
          <PrivacyPolicyButtonWrapper>
            <PrivacyPolicyButton
              variant='secondary'
              onClick={() => {
                window.open('https://new.abb.com/privacy-notice', '_blank');
              }}>
              <ButtonLabel style={{ marginRight: '0.5rem' }}>Privacy Policy</ButtonLabel>
              <MoreIcon />
            </PrivacyPolicyButton>
          </PrivacyPolicyButtonWrapper>
        </LogoContainer>
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
        <PrivacyPolicyLink to='https://new.abb.com/privacy-notice' target='_blank'>
          Privacy Policy
        </PrivacyPolicyLink>
      </Wrapper>
      {areCookieSettingsOpen && <CookieSettings onClose={acceptCookieSettings} />}
    </>
  );
};

export default MainLayout;
