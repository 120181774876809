import { useState } from 'react';

import { FormControl, FormHelperText } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { TextAreaCharCount } from './PersonalizedMessage.styles';
import { FormLabel, TextArea, TextInput } from '../../../../components/FormComponents/FormComponents.styles';
import { WizardStepProps } from '../types';

const PersonalizedMessage = ({ wishConfiguration, setCanContinue }: WizardStepProps) => {
  const [headlineCharCount, setHeadlineCharCount] = useState<number>(wishConfiguration.wishHeadline?.length ?? 0);
  const [messageCharCount, setMessageCharCount] = useState<number>(wishConfiguration.wishMessage?.length ?? 0);

  return (
    <>
      <FormControl display='flex' flexDir='column' width='100%'>
        <FormLabel>Headline (optional)</FormLabel>
        <TextInput
          type='text'
          className='!bg-white'
          onChange={e => {
            const trimmedValue = e.target.value.trim();
            wishConfiguration.wishHeadline = trimmedValue;
            setHeadlineCharCount(trimmedValue.length);
          }}
          width='100%'
          defaultValue={wishConfiguration.wishHeadline?.trim()}
          maxLength={
            messageCharCount + headlineCharCount === 200
              ? wishConfiguration.wishHeadline?.length
              : (wishConfiguration.wishMessage ?? '').length > 150
              ? 200 - (wishConfiguration.wishMessage ?? '')?.length
              : 50
          }
          style={{ fontSize: '1.2vw', marginTop: '0.5rem' }}
        />
      </FormControl>

      <FormControl isRequired display='flex' flexDir='column' width='100%' marginTop='1rem'>
        <FormLabel>Your message</FormLabel>
        <TextArea
          width='100%'
          minHeight='5.5rem'
          defaultValue={wishConfiguration.wishMessage}
          style={{ marginTop: '0.5rem', fontSize: '1.2vw' }}
          onChange={e => {
            const trimmedValue = e.target.value.trim();
            wishConfiguration.wishMessage = trimmedValue;
            setMessageCharCount(trimmedValue.length);
            setCanContinue(!isEmpty(trimmedValue));
          }}
          maxLength={200 - headlineCharCount}
        />
        <FormHelperText textAlign='right' marginTop='0.5rem'>
          <TextAreaCharCount>{`${messageCharCount + headlineCharCount}/200`}</TextAreaCharCount>
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default PersonalizedMessage;
