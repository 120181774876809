import { Link, Wrapper, WrapperRow } from './CookieSettings.styles';
import CloseIcon from '../Icons/CloseIcon';
import { Body, H3 } from '../Typography/Typography.styles';

const CookieSettings = ({ onClose }: { onClose: () => void }) => {
  return (
    <Wrapper>
      <WrapperRow>
        <H3 style={{ flexGrow: 1 }}>Cookie settings</H3>
        <CloseIcon onClick={onClose} />
      </WrapperRow>
      <WrapperRow>
        <Body>
          Our website uses cookies which are necessary for running the website and for providing the services you
          request. For more information, please read our{' '}
          <Link
            href='https://new.abb.com/privacy-policy'
            target='_blank'
            rel='noreferrer'
            style={{ color: '#262626 !important' }}>
            Cookie Information.
          </Link>
        </Body>
      </WrapperRow>
    </Wrapper>
  );
};

export default CookieSettings;
