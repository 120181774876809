import { IconProps } from './types';

const AddIcon = ({ fill = '#262626', width = 12, height = 10 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.5 6.5V11H6.5V6.5H11V5.5H6.5L6.5 1L5.5 1L5.5 5.5L1 5.5L1 6.5L5.5 6.5Z'
        fill={fill}
      />
    </svg>
  );
};

export default AddIcon;
