import { IconProps } from './types';

const DownloadIcon = ({ fill = '#262626', width = 12, height = 10 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.85355 5.14645L10.2071 4.79289L9.5 4.08579L9.14645 4.43934L6.5 7.08579L6.5 0.5V0L5.5 0V0.5L5.5 7.08579L2.85355 4.43934L2.5 4.08579L1.79289 4.79289L2.14645 5.14645L5.64645 8.64645L6 9L6.35355 8.64645L9.85355 5.14645ZM1.5 11H1L1 12H1.5L10.5 12H11V11H10.5L1.5 11Z'
        fill={fill}
      />
    </svg>
  );
};

export default DownloadIcon;
