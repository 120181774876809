import { IconProps } from './types';

const InfoIcon = ({ fill = '#FF000F', width = 12, height = 12 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.4 9H6.6V5.4H5.4V9ZM6 4.2C6.17 4.2 6.3125 4.1425 6.4275 4.0275C6.5425 3.9125 6.6 3.77 6.6 3.6C6.6 3.43 6.5425 3.2875 6.4275 3.1725C6.3125 3.0575 6.17 3 6 3C5.83 3 5.6875 3.0575 5.5725 3.1725C5.4575 3.2875 5.4 3.43 5.4 3.6C5.4 3.77 5.4575 3.9125 5.5725 4.0275C5.6875 4.1425 5.83 4.2 6 4.2ZM6 12C5.17 12 4.39 11.8425 3.66 11.5275C2.93 11.2125 2.295 10.785 1.755 10.245C1.215 9.705 0.7875 9.07 0.4725 8.34C0.1575 7.61 0 6.83 0 6C0 5.17 0.1575 4.39 0.4725 3.66C0.7875 2.93 1.215 2.295 1.755 1.755C2.295 1.215 2.93 0.7875 3.66 0.4725C4.39 0.1575 5.17 0 6 0C6.83 0 7.61 0.1575 8.34 0.4725C9.07 0.7875 9.705 1.215 10.245 1.755C10.785 2.295 11.2125 2.93 11.5275 3.66C11.8425 4.39 12 5.17 12 6C12 6.83 11.8425 7.61 11.5275 8.34C11.2125 9.07 10.785 9.705 10.245 10.245C9.705 10.785 9.07 11.2125 8.34 11.5275C7.61 11.8425 6.83 12 6 12ZM6 10.8C7.34 10.8 8.475 10.335 9.405 9.405C10.335 8.475 10.8 7.34 10.8 6C10.8 4.66 10.335 3.525 9.405 2.595C8.475 1.665 7.34 1.2 6 1.2C4.66 1.2 3.525 1.665 2.595 2.595C1.665 3.525 1.2 4.66 1.2 6C1.2 7.34 1.665 8.475 2.595 9.405C3.525 10.335 4.66 10.8 6 10.8Z'
        fill={fill}
      />
    </svg>
  );
};

export default InfoIcon;
