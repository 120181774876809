// LOCAL
export const REACT_SECRET_KEY = 'WoxY4pwtGJ21YvKabCZrpxsBN0k7WAreNd1LraJJDWI=';
export const REACT_IV = 'MENzvd8wcV9ninOqFR1kng==';
export const REACT_BE_BASE_URL = 'https://abbecardbedev.agreeabledesert-0cf6d511.westeurope.azurecontainerapps.io';
export const REACT_REDIRECT_URI = 'https://dev.seasons-greetings.abb.com/login';
export const REACT_AUTHORITY = 'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd';
export const REACT_CLIENT_ID = '0af35627-9b3b-4641-bb02-5f7ce2d8284f';
export const REACT_FE_BASE_URI = 'https://dev.seasons-greetings.abb.com';
export const SENDER_FLOW_ACTIVE_UNTIL = '';
export const RECEIVER_FLOW_ACTIVE_UNTIL = '2024-07-01';
