import { isEmpty, uniqBy } from 'lodash';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import { WishRecipient } from '../../pages/Sender/CreationWizard/types';
import { isValidEmail } from '../../pages/Sender/CreationWizard/utils';

export const parseCsvFiles = (
  file: File,
): Promise<{ validRecipients: Array<WishRecipient>; numberOfInvalidEntries: number }> => {
  return new Promise((resolve, reject) => {
    let step = 0;
    let hasError = false;
    const recipients: Array<WishRecipient> = [];
    let numberOfInvalidEntries = 0;
    Papa.parse(file, {
      worker: true, // Don't bog down the main thread if its a big file
      delimiter: ',',
      step: function (result) {
        const { data } = result;
        const arrayData = data as Array<string>;

        if (arrayData.every(keyOrVal => isEmpty(keyOrVal))) {
          return;
        }

        if (step === 0) {
          const [nameHeader, EmailHeader] = arrayData;

          if (nameHeader !== 'Name' || EmailHeader !== 'Email') {
            hasError = true;
          }
        } else if (!hasError) {
          const [name, email] = arrayData;
          const nameValid = !isEmpty(name);
          const emailValid = !isEmpty(email) && isValidEmail(email);

          if (nameValid && emailValid) {
            recipients.push({ id: uuidv4(), name, email });
          } else {
            numberOfInvalidEntries++;
          }
        }
        step++;
      },
      complete: () => {
        const uniqueRecipients = uniqBy(recipients, 'email');
        if (!hasError)
          resolve({
            validRecipients: uniqueRecipients,
            numberOfInvalidEntries: numberOfInvalidEntries + (recipients.length - uniqueRecipients.length),
          });
        reject();
      },
      error: () => {
        reject();
      },
    });
  });
};
