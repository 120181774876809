import WishChoice from './GreetingChoice/GreetingChoice';
import PersonalizedMessage from './PersonalizedMessage/PersonalizedMessage';
import RecipientAddition from './RecipientAddition/RecipientAddition';
import { WizardSteps } from './types';
import WishesPreview from './WishesPreview/WishesPreview';

type WizardConfigType = Record<
  WizardSteps,
  {
    stepIndex: number;
    heading: string;
    leadParagraph: string;
    StepComponent?: any;
  }
>;

export const wizardConfig: WizardConfigType = {
  [WizardSteps.WIZARD_GREETING_CHOICE]: {
    stepIndex: 0,
    heading: 'Season’s Greetings',
    leadParagraph: `As the festive season approaches, it's the perfect time to express gratitude and warm wishes to your colleagues, partners and customers. Choose a theme from the three options below and share an animated e-card with everyone on your list.`,
    StepComponent: WishChoice,
  },
  [WizardSteps.WIZARD_PERSONALIZE_MESSAGE]: {
    stepIndex: 1,
    heading: 'Write a personalized message',
    leadParagraph: `Create your own greeting text here and it will be included${'\n'}in your card.`,
    StepComponent: PersonalizedMessage,
  },
  [WizardSteps.WIZARD_RECIPIENT_ADDITION]: {
    stepIndex: 2,
    heading: 'Add recipients',
    leadParagraph: `You can easily import a list of recipients for your card.${'\n'}Simply upload a CSV list by clicking on the button below${'\n'}and following the instructions.`,
    StepComponent: RecipientAddition,
  },
  [WizardSteps.WIZARD_WISH_PREVIEW]: {
    stepIndex: 3,
    heading: 'Wishes preview',
    leadParagraph: 'Your wishes are now ready to be sent.',
    StepComponent: WishesPreview,
  },
  [WizardSteps.WIZARD_COMPLETION]: {
    stepIndex: 4,
    heading: 'Season’s Greetings',
    leadParagraph:
      'Thank you for sending an ABB e-card. Your wishes have been sent.\n\nWarm wishes for you and your loved ones this season!',
  },
};
