import CryptoJS from 'crypto-js';

import { REACT_IV, REACT_SECRET_KEY } from '../../../constants/variables';

export const decryptString = (encryptedMessage: string) => {
  const key = CryptoJS.enc.Base64.parse(REACT_SECRET_KEY);
  const iv = CryptoJS.enc.Base64.parse(REACT_IV);
  const text = CryptoJS.enc.Hex.parse(encryptedMessage);
  const encryptedCP = CryptoJS.lib.CipherParams.create({
    ciphertext: text,
    formatter: CryptoJS.format.Hex,
  });

  const decrypted = CryptoJS.AES.decrypt(encryptedCP, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    format: CryptoJS.format.Hex,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
