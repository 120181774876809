import { IconProps } from './types';

const ReplayIcon = ({ fill = '#262626', width = 14, height = 14 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.99998 13.6663C5.13887 13.6663 3.56248 13.0205 2.27081 11.7288C0.979146 10.4372 0.333313 8.86079 0.333313 6.99967C0.333313 5.13856 0.979146 3.56217 2.27081 2.27051C3.56248 0.978841 5.13887 0.333008 6.99998 0.333008C7.95831 0.333008 8.87498 0.530786 9.74998 0.926341C10.625 1.3219 11.375 1.88801 12 2.62467V0.333008H13.6666V6.16634H7.83331V4.49967H11.3333C10.8889 3.7219 10.2811 3.11079 9.50998 2.66634C8.73887 2.2219 7.9022 1.99967 6.99998 1.99967C5.61109 1.99967 4.43054 2.48579 3.45831 3.45801C2.48609 4.43023 1.99998 5.61079 1.99998 6.99967C1.99998 8.38856 2.48609 9.56912 3.45831 10.5413C4.43054 11.5136 5.61109 11.9997 6.99998 11.9997C8.06942 11.9997 9.0347 11.6941 9.89581 11.083C10.7569 10.4719 11.3611 9.66634 11.7083 8.66634H13.4583C13.0694 10.1386 12.2778 11.34 11.0833 12.2705C9.88887 13.2011 8.52776 13.6663 6.99998 13.6663Z'
        fill={fill}
      />
    </svg>
  );
};

export default ReplayIcon;
