import styled from '@emotion/styled';

export const HorizontalLine = styled.hr`
  width: 100%;
  background: ${(props: any) => props.theme.colors['gray-4']};
  height: 0.1rem;
  border: none;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const RecipientWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const RecipientRow = styled.div<{ index: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${(props: any) => (props.index > 0 ? '2rem' : '0px')};
  justify-items: center;
  align-items: flex-start;
`;

export const AddButtonWrapper = styled.div`
  margin-top: 2rem;
`;
