import { useEffect, useState } from 'react';

export const useDeviceChecker = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const isMobile = width <= 768;

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setWidth(window.innerWidth);
      });
    };
  }, []);

  return {
    isMobile,
  };
};
