import { Input, Textarea } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Label } from '../Typography/Typography.styles';

export const TextInput = styled(Input)`
  border: none;
  border-bottom: ${(props: any) => `2px solid ${props.theme.colors['gray-4']}`};
  color: ${(props: any) => props.theme.colors['gray-1']};
  padding: 1.25rem 0rem;
  box-sizing: border-box;
  height: 2.5rem;
  outline: none;
  background-color: transparent;
  &:focus {
    border-color: ${(props: any) => props.theme.colors['gray-1']};
    color: ${(props: any) => props.theme.colors['gray-1']};
  }
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: left;
`;

export const TextArea = styled(Textarea)`
  border: none;
  border: ${(props: any) => `2px solid ${props.theme.colors['gray-4']}`};
  color: ${(props: any) => props.theme.colors['gray-1']};
  background-color: ${(props: any) => props.theme.colors['gray-6']};
  padding: 0.25rem;
  box-sizing: border-box;
  height: 2.5rem;
  outline: none;
  font-family: 'ABBVoice';
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  &:focus {
    border-color: ${(props: any) => props.theme.colors['gray-1']};
    color: ${(props: any) => props.theme.colors['gray-1']};
  }
`;

export const FormLabel = styled(Label)`
  color: ${(props: any) => props.theme.colors['gray-2']};
`;
