import { IconProps } from './types';

const PreviousIcon = ({ fill = '#FFF', width = 12, height = 10 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.87666 5.53516L12.002 5.53514L12.002 6.53514L1.94777 6.53516L5.59413 10.1816L4.88702 10.8887L0.351585 6.35318L-0.0019527 5.99964L0.351577 5.64608L4.88682 1.11058L5.59395 1.81766L1.87666 5.53516Z'
        fill={fill}
      />
    </svg>
  );
};

export default PreviousIcon;
