export enum WizardSteps {
  WIZARD_GREETING_CHOICE = 'WIZARD_GREETING_CHOICE',
  WIZARD_PERSONALIZE_MESSAGE = 'WIZARD_PERSONALIZE_MESSAGE',
  WIZARD_RECIPIENT_ADDITION = 'WIZARD_RECIPIENT_ADDITION',
  WIZARD_WISH_PREVIEW = 'WIZARD_WISH_PREVIEW',
  WIZARD_COMPLETION = 'WIZARD_COMPLETION',
}

export type WizardStepProps = {
  wishConfiguration: WishConfiguration;
  setCanContinue: (canContinue: boolean) => void;
  isSubmitting: boolean;
};

export enum GreetingType {
  WINTER_GARDEN = 'WINTER GARDEN',
  SNOW_GLOBE = 'SNOW GLOBE',
  SPECIAL_GIFT = 'SPECIAL GIFT',
}

export type WishRecipient = {
  id: string;
  name?: string;
  email?: string;
};

export type WishConfiguration = {
  greetingType?: GreetingType;
  wishHeadline?: string;
  wishMessage?: string;
  recipients?: Array<WishRecipient>;
  sender?: string;
};
