import { GreetingType } from '../../pages/Sender/CreationWizard/types';

export const DEFAULT_VIDEO = '/videos/main_bg.mp4';

export const senderVideoConfiguration = {
  [GreetingType.WINTER_GARDEN]: '/videos/sender/WinterGarden_BG.mp4',
  [GreetingType.SNOW_GLOBE]: '/videos/sender/SnowGlobeGB.mp4',
  [GreetingType.SPECIAL_GIFT]: '/videos/sender/SpecialGift_BG.mp4',
};

export const receiverVideoConfiguration = {
  [GreetingType.WINTER_GARDEN]: '/videos/receiver/WinterGarden_BG.mp4',
  [GreetingType.SNOW_GLOBE]: '/videos/receiver/SnowGlobe_BG.mp4',
  [GreetingType.SPECIAL_GIFT]: '/videos/receiver/SpecialGift_BG.mp4',
};
