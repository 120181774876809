import { useMsal } from '@azure/msal-react';
import { isEmpty } from 'lodash';

import { loginRequest } from '../config/auth';

export const useAuth = () => {
  const { instance } = useMsal();

  const activeAccount = instance.getActiveAccount();
  const accessToken = sessionStorage.getItem('access-token') ?? '';

  const onLoginRedirect = async () => {
    await instance.loginRedirect(loginRequest);
  };

  return {
    isLoggedIn: !isEmpty(activeAccount),
    activeAccount,
    onLoginRedirect,
    accessToken,
  };
};
