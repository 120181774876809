import { IconProps } from './types';

const EditIcon = ({ fill = '#262626', width = 12, height = 12 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 3.15141L7.84859 1L2 6.84859V9H4.15141L10 3.15141ZM2.97476 8.02523V7.25235L7.84859 2.37853L8.62147 3.15141L3.74765 8.02523H2.97476ZM11 10L1 10L1 11L11 11V10Z'
        fill={fill}
      />
    </svg>
  );
};

export default EditIcon;
