import { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';

import { RouteNames } from '../../routes/constants';

const Login = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount) {
      navigate(`/${RouteNames.WISH_WIZARD}`);
    }
  }, []);

  useEffect(() => {
    if (activeAccount) {
      navigate(`/${RouteNames.WISH_WIZARD}`);
    }
  }, [activeAccount]);

  return null;
};

export default Login;
