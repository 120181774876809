import { IconProps } from './types';

const UnMuteIcon = ({ fill = '#262626', width = 20, height = 18 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.99967 0.5V15.5H8.33301L4.16634 11.3333H2.06634C1.74068 11.3333 1.42823 11.2045 1.19718 10.975C0.966119 10.7456 0.835208 10.434 0.833008 10.1083V5.94167C0.835192 5.60419 0.970224 5.28116 1.20886 5.04252C1.4475 4.80388 1.77053 4.66885 2.10801 4.66667H4.16634L8.33301 0.5H9.99967ZM17.258 2.40833C19.658 4.81667 19.658 11.1833 17.258 13.5917L16.0747 12.4083C17.8497 10.6417 17.8497 5.35833 16.0747 3.59167L17.258 2.40833ZM14.758 4.90833C15.5228 5.75647 15.9461 6.85797 15.9461 8C15.9461 9.14203 15.5228 10.2435 14.758 11.0917L13.5747 9.90833C14.0269 9.37529 14.2751 8.699 14.2751 8C14.2751 7.301 14.0269 6.62471 13.5747 6.09167L14.758 4.90833Z'
        fill={fill}
      />
    </svg>
  );
};

export default UnMuteIcon;
