/* eslint-disable no-case-declarations */
import { useEffect, useRef, useState } from 'react';

import { useTheme } from '@emotion/react';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid';

import { wizardConfig } from './config';
import { WizardFooter, WizardStepper } from './CreationWizard.styles';
import { GreetingType, WishConfiguration, WizardSteps } from './types';
import { isEmailDuplicate, isValidEmail } from './utils';
import { Button, IconButton } from '../../../components/Button/Button.styles';
import ContentContainer from '../../../components/ContentContainer/ContentContainer';
import NextIcon from '../../../components/Icons/NextIcon';
import PreviousIcon from '../../../components/Icons/PreviousIcon';
import { ButtonLabel } from '../../../components/Typography/Typography.styles';
import { useAuth } from '../../../hooks/useAuth';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { createCard } from '../../../services/api.service';

const CreationWizard = () => {
  const theme = useTheme();
  const [searchParam, setSearchParams] = useSearchParams();

  const { activeAccount, accessToken } = useAuth();
  const { stepName } = useQueryParams();

  const [currentStep, setCurrentStep] = useState(
    (stepName?.toUpperCase() as WizardSteps) ?? WizardSteps.WIZARD_GREETING_CHOICE,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canContinue, setCanContinue] = useState(false);

  const wishConfiguration = useRef<WishConfiguration>({
    recipients: [{ id: uuidv4(), name: '', email: '' }],
    greetingType: GreetingType.WINTER_GARDEN,
    sender: activeAccount?.name,
  });

  useEffect(() => {
    if (!stepName) {
      setSearchParams({
        step: WizardSteps.WIZARD_GREETING_CHOICE.toLowerCase(),
        wishType: wishConfiguration.current.greetingType?.toLowerCase().replaceAll(' ', '_') ?? '',
      });

      return;
    }

    const parsedStepName = stepName?.toUpperCase() as WizardSteps;

    if (currentStep !== parsedStepName) {
      setCurrentStep(parsedStepName ?? WizardSteps.WIZARD_GREETING_CHOICE);
    }

    switch (parsedStepName) {
      case WizardSteps.WIZARD_GREETING_CHOICE:
        setCanContinue(!isEmpty(wishConfiguration.current.greetingType));
        break;
      case WizardSteps.WIZARD_PERSONALIZE_MESSAGE:
        setCanContinue(!isEmpty(wishConfiguration.current.wishMessage));
        break;
      case WizardSteps.WIZARD_RECIPIENT_ADDITION:
        const allNamesValid = wishConfiguration.current.recipients?.every(rec => !isEmpty(rec.name?.trim())) ?? false;
        const allEmailsValid =
          wishConfiguration.current.recipients?.every(
            (rec, index) =>
              !isEmpty(rec.email) &&
              isValidEmail(rec.email ?? '') &&
              !isEmailDuplicate(rec.email ?? '', wishConfiguration.current.recipients ?? [], index),
          ) ?? false;
        setCanContinue(allNamesValid && allEmailsValid);
        break;
      case WizardSteps.WIZARD_WISH_PREVIEW:
        setCanContinue(true);
        break;
    }
  }, [searchParam]);

  const onPrevious = () => {
    switch (currentStep) {
      case WizardSteps.WIZARD_PERSONALIZE_MESSAGE:
        setCurrentStep(WizardSteps.WIZARD_GREETING_CHOICE);
        setSearchParams({
          step: WizardSteps.WIZARD_GREETING_CHOICE.toLowerCase(),
          wishType: wishConfiguration.current.greetingType?.toLowerCase().replaceAll(' ', '_') ?? '',
        });
        setCanContinue(false);
        break;
      case WizardSteps.WIZARD_RECIPIENT_ADDITION:
        setCurrentStep(WizardSteps.WIZARD_PERSONALIZE_MESSAGE);
        setSearchParams({ step: WizardSteps.WIZARD_PERSONALIZE_MESSAGE.toLowerCase() });
        setCanContinue(false);
        break;
      case WizardSteps.WIZARD_WISH_PREVIEW:
        setCurrentStep(WizardSteps.WIZARD_RECIPIENT_ADDITION);
        setSearchParams({ step: WizardSteps.WIZARD_RECIPIENT_ADDITION.toLowerCase() });
        setCanContinue(false);
        break;
    }
  };

  const onNext = () => {
    switch (currentStep) {
      case WizardSteps.WIZARD_GREETING_CHOICE:
        setCurrentStep(WizardSteps.WIZARD_PERSONALIZE_MESSAGE);
        setSearchParams({ step: WizardSteps.WIZARD_PERSONALIZE_MESSAGE.toLowerCase() });
        setCanContinue(!isEmpty(wishConfiguration.current.wishMessage));
        break;
      case WizardSteps.WIZARD_PERSONALIZE_MESSAGE:
        setCurrentStep(WizardSteps.WIZARD_RECIPIENT_ADDITION);
        setSearchParams({ step: WizardSteps.WIZARD_RECIPIENT_ADDITION.toLowerCase() });
        setCanContinue(false);
        break;
      case WizardSteps.WIZARD_RECIPIENT_ADDITION:
        setCurrentStep(WizardSteps.WIZARD_WISH_PREVIEW);
        setSearchParams({ step: WizardSteps.WIZARD_WISH_PREVIEW.toLowerCase() });
        setCanContinue(false);
        break;
      case WizardSteps.WIZARD_WISH_PREVIEW:
        setIsSubmitting(true);
        createCard(wishConfiguration.current, accessToken, () => {}).then(() => {
          setIsSubmitting(false);
          setCurrentStep(WizardSteps.WIZARD_COMPLETION);
          setSearchParams({ step: WizardSteps.WIZARD_COMPLETION.toLowerCase() });
        });
        break;
    }
  };

  const currentStepConfig = wizardConfig[currentStep];

  return (
    <ContentContainer heading={currentStepConfig.heading} leadParagraph={currentStepConfig.leadParagraph}>
      {currentStep === WizardSteps.WIZARD_COMPLETION && (
        <Button
          variant='primary'
          onClick={() => {
            setSearchParams({
              step: WizardSteps.WIZARD_GREETING_CHOICE.toLowerCase(),
              wishType: wishConfiguration.current.greetingType?.toLowerCase().replaceAll(' ', '_') ?? '',
            });
            wishConfiguration.current = {
              recipients: [{ id: uuidv4(), name: '', email: '' }],
              greetingType: GreetingType.WINTER_GARDEN,
              sender: activeAccount?.name,
            };
            setCanContinue(!isEmpty(wishConfiguration.current.greetingType));
          }}
          style={{ marginTop: '2rem' }}>
          <ButtonLabel>Create new e-card</ButtonLabel>
        </Button>
      )}
      {currentStepConfig.StepComponent && (
        <currentStepConfig.StepComponent
          wishConfiguration={wishConfiguration.current}
          setCanContinue={setCanContinue}
          isSubmitting={isSubmitting}
        />
      )}
      {currentStep !== WizardSteps.WIZARD_COMPLETION && (
        <WizardFooter>
          {currentStepConfig.stepIndex > 0 && (
            <IconButton variant='secondary' onClick={onPrevious} disabled={isSubmitting}>
              <PreviousIcon fill={(theme as any).colors['gray-1']} />
            </IconButton>
          )}
          <Button
            variant='primary'
            onClick={onNext}
            disabled={!canContinue || isSubmitting}
            style={{ marginLeft: '1rem' }}>
            {currentStep === WizardSteps.WIZARD_WISH_PREVIEW ? (
              <>
                <ButtonLabel style={{ marginRight: '0.5rem' }}>Send e-card</ButtonLabel>
                {isSubmitting && <ClipLoader color='#262626' size={12} />}
              </>
            ) : (
              <>
                <ButtonLabel style={{ marginRight: '0.5rem' }}>Next</ButtonLabel>
                <NextIcon fill={!canContinue ? (theme as any).colors['gray-3'] : undefined} />
              </>
            )}
          </Button>
          <WizardStepper>{`Step ${currentStepConfig.stepIndex + 1}/${
            Object.keys(wizardConfig).length - 1
          }`}</WizardStepper>
        </WizardFooter>
      )}
    </ContentContainer>
  );
};

export default CreationWizard;
