import { ChangeEvent, useRef, useState } from 'react';

import {
  DragFileElement,
  ErrorMessage,
  FileInput,
  FileUploadBoundary,
  FormWrapper,
  FormatLabel,
} from './FileUpload.styles';
import { parseCsvFiles } from './utils';
import { WishRecipient } from '../../pages/Sender/CreationWizard/types';
import InfoIcon from '../Icons/Info';
import { Body, Link } from '../Typography/Typography.styles';

const FileUpload = ({
  recipients,
  setRecipients,
}: {
  recipients: Array<WishRecipient>;
  setRecipients: (recipients: Array<WishRecipient>) => void;
}) => {
  const [file, setFile] = useState<File>();
  const [hasProcessingError, setHasProcessingError] = useState<boolean>(false);

  const [dragActive, setDragActive] = useState(false);
  const [faultyRecipients, setFaultyRecipients] = useState(0);

  const inputRef = useRef<any>(null);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
      await parseCsvFiles(e.dataTransfer.files[0])
        .then(recipients => {
          setRecipients(recipients.validRecipients);
          setFaultyRecipients(recipients.numberOfInvalidEntries);
        })
        .catch(() => setHasProcessingError(true));
    }
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      await parseCsvFiles(e.target.files[0])
        .then(recipients => {
          setRecipients(recipients.validRecipients);
          setFaultyRecipients(recipients.numberOfInvalidEntries);
        })
        .catch(() => {
          setHasProcessingError(true);
        });
    }
    e.target.value = '';
    e.target.files = null;
  };

  return (
    <FormWrapper onDragEnter={handleDrag} onSubmit={e => e.preventDefault()}>
      <FileInput ref={inputRef} type='file' onChange={handleChange} accept='text/csv' />

      <FileUploadBoundary className={dragActive ? 'drag-active' : ''}>
        {!file && (
          <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M24.2188 24.375L20.2188 29.375L21.7808 30.625L23.9998 27.851V39H25.9998V27.851L28.2188 30.625L29.7808 29.375L25.7808 24.375H24.2188Z'
              fill='#A9A9A9'
            />
            <path
              d='M37.726 18C37.562 18 37.229 18.03 36.878 18.068C36.095 12.905 31.643 9 26.31 9C22.173 9 18.245 11.397 16.445 14.926C15.092 14.311 13.699 14 12.292 14C6.617 14 2 18.555 2 24.152C2 29.582 6.44 34 11.898 34H20V32H11.898C7.543 32 4 28.479 4 24.152C4 19.657 7.72 16 12.292 16C13.685 16 15.072 16.389 16.416 17.156L17.798 16.752L17.93 16.489C19.211 13.257 22.657 11 26.31 11C30.937 11 34.749 14.616 34.988 19.232L36.113 20.172C36.76 20.09 37.564 20 37.726 19.999C41.244 20 44 22.688 44 26.118C44 29.416 41.416 32 38.118 32H30V34H38.118C42.538 34 46 30.538 46 26.119C46 21.566 42.365 18 37.726 18Z'
              fill='#A9A9A9'
            />
          </svg>
        )}
        {file && recipients.length > 0 && !hasProcessingError && (
          <svg width='48' height='49' viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M41 8.5H34V3.5L33 2.5H16L15.257 2.831L6.257 12.831L6 13.5V39.5L7 40.5H14V45.5L15 46.5H41L42 45.5V9.5L41 8.5ZM15 6.106V12.5H9.245L15 6.106ZM14.257 18.831L14 19.5V38.5H8V14.5H16L17 13.5V4.5H32V8.5H24L23.257 8.831L14.257 18.831ZM23 12.106V18.5H17.245L23 12.106ZM40 44.5H16V20.5H24L25 19.5V10.5H40V44.5Z'
              fill='#A9A9A9'
            />
          </svg>
        )}
        {(!file || hasProcessingError) && (
          <Link>
            Drop your file here, or{' '}
            <Link
              onClick={() => {
                inputRef.current?.click();
              }}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              browse
            </Link>
          </Link>
        )}
        {!file && <FormatLabel style={{ marginTop: '1rem' }}>Supports CSV format</FormatLabel>}
        {file && recipients.length > 0 && !hasProcessingError && (
          <>
            <Body style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
              Your file has been uploaded{'\n'}({recipients.length} out of {recipients.length + faultyRecipients}{' '}
              recipients were successfully imported to the system)
            </Body>
          </>
        )}
      </FileUploadBoundary>
      {hasProcessingError && (
        <ErrorMessage style={{ marginTop: '0.5rem' }}>
          <InfoIcon />
          <span style={{ marginLeft: '0.5rem' }}>
            We could not process your CSV file, please make sure that it follows the structure of the template file.
          </span>
        </ErrorMessage>
      )}
      {faultyRecipients > 0 && (
        <ErrorMessage style={{ marginTop: '0.5rem' }}>
          <InfoIcon />
          <span style={{ marginLeft: '0.5rem' }}>
            {faultyRecipients} out of {recipients.length + faultyRecipients} recipients contain invalid data. You can
            still import them and manually correct or delete invalid recipients.
          </span>
        </ErrorMessage>
      )}
      {dragActive && (
        <DragFileElement
          id='drag-file-element'
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}></DragFileElement>
      )}
    </FormWrapper>
  );
};

export default FileUpload;
