import { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';

const ProtectedRoute = ({ children }: { children: any }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (!activeAccount) {
      navigate(`/`);
    }
  }, [activeAccount]);

  return children;
};

export default ProtectedRoute;
