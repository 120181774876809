import { IconProps } from './types';

const CloseIcon = ({ fill = '#262626', width = 20, height = 20, onClick, cursor = 'pointer' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{ cursor }}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 10.7071L15.6465 16.3536L16.3536 15.6465L10.7071 10L16.3536 4.35359L15.6465 3.64648L10 9.29293L4.35359 3.64648L3.64648 4.35359L9.29293 10L3.64648 15.6465L4.35359 16.3536L10 10.7071Z'
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
