import styled from '@emotion/styled';

import { Label } from '../Typography/Typography.styles';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  position: relative;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileUploadBoundary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 10rem;
  background-color: ${(props: any) => props.theme.colors['gray-5']};
  justify-content: center;
  align-items: center;
`;

export const FormatLabel = styled(Label)`
  color: ${(props: any) => props.theme.colors['gray-3']};
`;

export const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const ErrorMessage = styled.div`
  font-size: 1vw;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props: any) => props.theme.colors.red};
  word-wrap: break-word;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;
