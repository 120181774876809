import { WishRecipient } from './types';

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email) && email.split('').every(char => char !== ' ');
};

export const isEmailDuplicate = (email: string, recipients: Array<WishRecipient>, currentEmailIndex: number) => {
  const recCopy = [...recipients];
  recCopy.splice(currentEmailIndex, 1);

  return recCopy.map(rec => rec.email).includes(email);
};
