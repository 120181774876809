import ContentContainer from '../../components/ContentContainer/ContentContainer';

const Unavailability = () => {
  return (
    <ContentContainer
      heading='Seasons Greetings'
      leadParagraph='Thank you for visiting. The ABB e-card is currently offline.'>
      <></>
    </ContentContainer>
  );
};

export default Unavailability;
