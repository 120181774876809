import { useState } from 'react';

import { ModalOverlay, ModalWrapper, WrapperRow } from './Modal.styles';
import { WishRecipient } from '../../pages/Sender/CreationWizard/types';
import { Button, SmallButton } from '../Button/Button.styles';
import FileUpload from '../FileUpload/FileUpload';
import CloseIcon from '../Icons/CloseIcon';
import DownloadIcon from '../Icons/DownloadIcon';
import { BodyBold, BodyLight, H2, H2Cursor, Label } from '../Typography/Typography.styles';

const Modal = ({
  receiveRecipientsFromCSV,
  onClose,
}: {
  receiveRecipientsFromCSV: (recipients: Array<WishRecipient>) => void;
  onClose: () => void;
}) => {
  const [recipients, setRecipients] = useState<Array<WishRecipient>>([]);

  return (
    <ModalOverlay>
      <ModalWrapper>
        <WrapperRow>
          <H2Cursor style={{ flexDirection: 'column', display: 'flex', flexGrow: 1 }}>—</H2Cursor>
          <CloseIcon onClick={onClose} />
        </WrapperRow>
        <WrapperRow>
          <H2>Step-by-step to upload your csv contact list</H2>
        </WrapperRow>
        <WrapperRow style={{ marginTop: '2rem' }}>
          <BodyBold style={{ whiteSpace: 'break-spaces' }}>
            To upload an existing CSV contact list, please follow these instructions:
            {'\n'}
            {'\n'}
            1.{'\t'}Download and fill the template:{' '}
            <BodyLight>
              Download the template for the CSV file and fill it with your contacts according to the structure presented
              in the document.
            </BodyLight>
            {'\n'}
            2.{'\t'}Start Upload:{' '}
            <BodyLight>
              {' '}
              Select your pre-created CSV file from your computer by using the upload area below. The system will start
              uploading the contact list.
            </BodyLight>
            {'\n'}
            3.{'\t'}Wait for Completion:{' '}
            <BodyLight>
              Allow the system some time to process and upload your contacts. This may take a moment, depending on the
              file&apos;s size.
            </BodyLight>
            {'\n'}
            4. {'\t'}Confirmation:{' '}
            <BodyLight>
              Once the upload is finished, you should receive a confirmation message indicating the number of contacts
              successfully imported, and you will be able to go back to edit the recipient list.
            </BodyLight>
          </BodyBold>
        </WrapperRow>
        <WrapperRow style={{ marginTop: '2rem' }}>
          <a href='../templates/Sample.csv' download='Sample File.csv' style={{ textDecoration: 'none' }}>
            <SmallButton variant='secondary'>
              <Label style={{ marginRight: '0.5rem' }}>Download template File</Label>
              <DownloadIcon />
            </SmallButton>
          </a>
        </WrapperRow>
        <WrapperRow style={{ marginTop: '2rem' }}>
          <FileUpload
            setRecipients={(rec: Array<WishRecipient>) => setRecipients([...recipients, ...rec])}
            recipients={recipients}
          />
        </WrapperRow>
        <WrapperRow style={{ justifyContent: 'flex-end', marginTop: '2rem' }}>
          <Button
            variant='primary'
            disabled={recipients.length === 0}
            onClick={() => receiveRecipientsFromCSV(recipients)}>
            Confirm
          </Button>
        </WrapperRow>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default Modal;
