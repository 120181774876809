import React from 'react';

import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@emotion/react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { msalConfig } from './config/auth';
import reportWebVitals from './reportWebVitals';
import { routes } from './routes/routes';
import theme from './themes/theme';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  const activeAccount = msalInstance.getActiveAccount();

  if (activeAccount) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(activeAccount);
  }
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback(async (event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    await msalInstance.acquireTokenSilent(account).then(val => {
      sessionStorage.setItem('access-token', val.accessToken);
    });

    return;
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    // eslint-disable-next-line no-console
    alert('ERROR');
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <RouterProvider router={createBrowserRouter(routes)} />
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
