import styled from '@emotion/styled';

import { IconButton } from '../../../components/Button/Button.styles';

export const ReceiverWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50vw;
  height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  justify-content: center;
  padding: 4rem;
  transition: all 500ms;
`;

export const ReceiverWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  justify-content: center;
  transition: all 500ms;
  opacity: 0;
`;

export const MuteIconWrapper = styled(IconButton)`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  margin-top: 4rem;
  margin-right: 4rem;
`;

export const ReplayIconWrapper = styled(IconButton)`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  margin-top: 4rem;
  margin-right: 4rem;
  background: transparent;
`;
