import { REACT_BE_BASE_URL } from '../constants/variables';
import { WishConfiguration } from '../pages/Sender/CreationWizard/types';

export const createCard = async (
  wishConfiguration: WishConfiguration,
  token: string,
  rejectedCallback: (error: any) => void,
) => {
  try {
    const response = await fetch(`${REACT_BE_BASE_URL}/api/card`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(wishConfiguration),
    });

    const data = await response.json();

    return data;
  } catch (error: any) {
    rejectedCallback(error);

    return false;
  }
};
