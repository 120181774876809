import { IconProps } from './types';

const WishesPreviewIcon = ({ fill = '#262626', width = 12, height = 12 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99698 3.65587L2.08143 10.5717L1.37432 9.86456L8.2396 2.99904H3.99998L3.99998 1.99905L9.49695 1.99905L9.99694 1.99906L9.99695 2.49904L9.99998 8.00009L8.99998 8.00009L8.99698 3.65587Z'
        fill={fill}
      />
    </svg>
  );
};

export default WishesPreviewIcon;
