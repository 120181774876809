import { ReactNode } from 'react';

import {
  ContainerContentWrapper,
  ContainerLeadParagraph,
  ContainerWrapper,
  MiniWrapper,
} from './ContentContainer.styles';
import { H1, H1Cursor } from '../Typography/Typography.styles';

type ContentContainerProps = {
  heading?: string;
  leadParagraph?: string;
};

const ContentContainer = ({ children, heading, leadParagraph }: ContentContainerProps & { children: ReactNode }) => {
  return (
    <ContainerWrapper>
      <MiniWrapper>
        <H1Cursor>—</H1Cursor>
        {heading && <H1>{heading}</H1>}
        {leadParagraph && <ContainerLeadParagraph>{leadParagraph}</ContainerLeadParagraph>}
        <ContainerContentWrapper>{children}</ContainerContentWrapper>
      </MiniWrapper>
    </ContainerWrapper>
  );
};

export default ContentContainer;
