import { useSearchParams } from 'react-router-dom';

export const useQueryParams = () => {
  const [searchParam] = useSearchParams();

  return {
    stepName: searchParam.get('step'),
    wishType: searchParam.get('wishType'),
  };
};
