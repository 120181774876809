import { FormErrorMessage } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const H1 = styled.span`
  font-size: 2.6vw;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-align: left;
  word-wrap: break-word;
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 120%;
  }
`;

export const H1Cursor = styled(H1)`
  color: red;
`;

export const H2 = styled.span`
  font-size: 2.1vw;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0em;
  text-align: left;
  word-wrap: break-word;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 130%;
  }
`;

export const H2Cursor = styled(H2)`
  color: black;
`;

export const H3 = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-align: left;
  word-wrap: break-word;
`;

export const H4 = styled.span`
  font-size: 1.5vw;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: left;
  word-wrap: break-word;
`;

export const LeadParagraph = styled.p`
  font-size: 1.5vw;

  font-weight: 300;
  line-height: 130%;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props: any) => props.theme.colors['gray-1']};
  word-wrap: break-word;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 130%;
  }
`;

export const BodyBold = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props: any) => props.theme.colors['gray-1']};
  word-wrap: break-word;
`;

export const Body = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props: any) => props.theme.colors['gray-1']};
  word-wrap: break-word;
`;

export const BodyLight = styled.span`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props: any) => props.theme.colors['gray-1']};
  word-wrap: break-word;
`;

export const Link = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props: any) => props.theme.colors['gray-1']};
  word-wrap: break-word;
`;

export const BodySmall = styled.span`
  font-size: 14px;
  line-height: 150%;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props: any) => props.theme.colors['gray-1']};
  word-wrap: break-word;
`;

export const BodySmallScalable = styled.span`
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props: any) => props.theme.colors['gray-1']};
  word-wrap: break-word;
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  word-wrap: break-word;
`;

export const ButtonLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  word-wrap: break-word;
`;

export const InputErrorMessage = styled(FormErrorMessage)`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props: any) => props.theme.colors.red};
  word-wrap: break-word;
`;
