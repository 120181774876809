import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: white;
  border-top: 2px solid red;
  padding: 2rem;
  z-index: 9999;
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 4rem);
`;

export const Link = styled.a`
  color: #262626;
  &:visited: {
    color: #262626;
  }
`;
