const theme = {
  colors: {
    red: '#FF000F',
    white: '#FFFFFF',
    black: '#000000',
    'primary-red-hover': '#BB2924',
    'gray-1': '#262626',
    'gray-2': '#6E6E6E',
    'gray-3': '#A9A9A9',
    'gray-4': '#D2D2D2',
    'gray-5': '#F0F0F0',
    'gray-6': '#FAFAFA',
  },
};

export default theme;
