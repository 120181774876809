import ContentContainer from '../../../components/ContentContainer/ContentContainer';
import { H2, LeadParagraph } from '../../../components/Typography/Typography.styles';

const HomeMobile = () => {
  return (
    <ContentContainer heading='Season’s Greetings'>
      <H2>Please change your device</H2>
      <LeadParagraph style={{ marginTop: '2rem' }}>
        Unfortunately, this website is not optimized for mobile use. Please open e-card creator on the desktop.
      </LeadParagraph>
    </ContentContainer>
  );
};

export default HomeMobile;
